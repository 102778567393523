<template>
  <div class="step-list" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="step-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <ul v-if="itemList.length" class="step-list__list">
      <li v-for="item in itemList" :key="item.id" class="step-list__item">
        <T3HtmlParser
          v-if="item.name"
          :tag="item.text ? 'h3' : 'div'"
          class="step-list__item-title"
          :content="item.name"
        />

        <T3HtmlParser
          v-if="item.text"
          class="step-list__item-text"
          :content="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()
const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.step-list {
  &__heading {
    margin-bottom: rem(64px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    padding: rem(24px);
    border-bottom: 1px solid color(wild-sand);

    &:first-of-type {
      border-top: 1px solid color(wild-sand);
    }

    @include media-query(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(32px);
    }

    &-title {
      font-size: rem(28px);

      @include media-query(md) {
        margin-bottom: 0;
      }
    }

    &-text p {
      margin-bottom: 0;
    }
  }
}
</style>
